import ApplicationController from '../lib/no_reflex/application_controller'

export default class extends ApplicationController {

  static targets = ['element', 'expanded', 'contracted']

  static values = {
    expanded: Boolean
  }

  // Make this a local storage thing? That would force it to apply to all pages
  // and I'm not sure that's desirable behaviour but at least it would survive
  // a page refresh?
  get headerExpanded() {
    return window.headerExpanded || false
  }

  set headerExpanded(value) {
    window.headerExpanded = value
  }

  connect() {
    super.connect()

    // On a turbo restore render action
    window.addEventListener('turbo:render', () => this.restore())

    if (this.expandedValue) {
      this.headerExpanded = true
    }

    this.restore()
  }

  toggle() {
    this.headerExpanded = !this.headerExpanded

    this.restore()
  }

  restore() {
    this.expandedTargets.forEach(e => e.classList.toggle('d-none', !this.headerExpanded))
    this.contractedTargets.forEach(e => e.classList.toggle('d-none', this.headerExpanded))

    // element targets have custom classes to apply/remove
    this.elementTargets.forEach(e => {
      const expandedClasses = e.dataset.headerExpandedClass
      const contractedClasses = e.dataset.headerContractedClass

      if (this.headerExpanded) {
        // Remove any contracted classes
        if (contractedClasses) e.classList.toggle(contractedClasses, false)

        // Apply expanded classes
        if (expandedClasses) e.classList.toggle(expandedClasses, true)
      }
      else {
        // Remove any expanded classes
        if (expandedClasses) e.classList.toggle(expandedClasses, false)

        // Apply contracted classes
        if (contractedClasses) e.classList.toggle(contractedClasses, true)
      }
    })
  }
}
