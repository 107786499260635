import ApplicationController from '../lib/with_reflex/application_controller'

import consumer from '../../channels/consumer'

export default class extends ApplicationController {

  static targets = ['icon']

  connect() {
    super.connect()

    var that = this

    consumer.subscriptions.create(
      {
        channel: 'Noticed::NotificationChannel'
      },
      {
        received(data) {
          that.iconTarget.classList.remove('fa-bell')
          that.iconTarget.classList.add('fa-bell-on', 'text-danger')
        }
      }
    )

  }

}
