/* eslint no-console:0 */

import $ from 'jquery'
window.jQuery = window.$ = $ // Make jquery global

import '../common'

import { application } from '../controllers/application'

// Load all controllers all the time?
import controllers from '../controllers/{common,frontend}/*_controller.js'
controllers.forEach((controller) => {
  application.register(
    controller.name.replace(/\.controllers--(common|admin|community|frontend)--/, ''),
    controller.module.default
  )
})

import StimulusReflex from 'stimulus_reflex'
StimulusReflex.initialize(application)

import consumer from '../channels/consumer'
application.consumer = consumer

// Need these from community when editing user profile
import MultiSelectController from '../controllers/community/multi_select_controller'
application.register('multi-select', MultiSelectController)

import AvatarController from '../controllers/community/avatar_controller'
application.register('avatar', AvatarController)

// Experimental view transitions
const viewTransitionHandler = (event) => {
  if (document.startViewTransition) {
    const originalRender = event.detail.render
    event.detail.render = (currentElement, newElement) => {
      document.startViewTransition(()=> originalRender(currentElement, newElement))
    }
  }
}

addEventListener('turbo:before-render', viewTransitionHandler)
addEventListener('turbo:before-frame-render', viewTransitionHandler)
