import ApplicationController from '../lib/no_reflex/application_controller'
import { Dropdown } from 'bootstrap-5'

export default class extends ApplicationController {
  static targets = ['display', 'input', 'option', 'default']

  get value() {
    return this.inputTarget.value
  }

  connect() {
    super.connect()

    let found;

    if (this.value) {
      found = this.optionTargets.find((t) => t.dataset['dropdownSelectValueParam'] == this.value)
    }

    if (found) {
      this.displayTarget.innerHTML = found.innerHTML
    }
    else {
      this.displayTarget.innerHTML = this.defaultTarget.innerHTML
    }
  }

  select(event) {
    event.preventDefault()

    this.inputTarget.value = event.params.value
    this.displayTarget.innerHTML = event.target.innerHTML
  }
}
