import ApplicationController from '../lib/no_reflex/application_controller'
import { Collapse } from 'bootstrap-5'

export default class extends ApplicationController {
  static values = {
    scrollSelector: String
  }

  connect() {
    if (window.location.hash && window.location.hash == `#${this.element.id}`) {
      if (this.element.classList.contains('collapse')) {
        Collapse.getOrCreateInstance(this.element).show()
      }

      let scrollTarget;

      if (this.hasScrollSelectorValue) {
        scrollTarget = document.querySelector(this.scrollSelectorValue);
      }

      scrollTarget = scrollTarget || this.element;
      scrollTarget.scrollIntoView()
    }
  }
}
