// This works to remove certain links based on the current_user id
// This is done mostly for hotwire, as it can stream updates from the model
// The model has no idea who the current user is, so this controller acts as a way to fix this
// A meta tag exists in the head which has the current user id
// We then check this against the given user-id-value and remove if the condition is met
// eg.
// 
// <div data-controller='for-current-user' data-for-current-user-user-id-value="<%= post.user.id %>">
//    <div class='edit-post-link' data-for-current-user-target='container'>
//    </div>
// </div>
// 
// This will remove the edit post link div if the current user does not match the author of the post.
// 
// We sometimes want to remove if the two ids are equal, for example a user can not dirrect message themselves.
// In this case we add in value to specify we are checking equality
// By default it checks for inequality
// 
// <div data-controller='for-current-user' data-for-current-user-user-id-value="<%= user.id %>" data-for-current-user-equality-value="true">
//    <div class='message-user' data-for-current-user-target='container'>
//    </div>
// </div>
//
// For sanity, use the provided rails helpers:
//
//   def show_for(user_id, options, html_options, &block)
//   def hide_for(user_id, options, html_options, &block)
//
// Like this:
//
//   <%= show_for(post.user) do %>
//     <!-- Sensitive things go here -->
//   <% end %>
//
// This is not the same thing as a permissions policy check but will work well
// enough in many cases that we may not need one.
//
import ApplicationController from '../lib/no_reflex/application_controller'

export default class extends ApplicationController {

  static targets = ['container']

  static values = { userId: String, equality: String }

  get currentUserId(){
    return document.querySelector('meta[name="current-user-id"]').content
  }

  get show() {
    if (this.equalityValue === 'true') {
      return this.userIdValue == this.currentUserId
    } else {
      return this.userIdValue != this.currentUserId
    }
  }

  get containers() {
    if (this.hasContainerTarget) {
      return this.containerTargets
    }
    else {
      return [this.element]
    }
  }

  connect(){
    if (!this.show) {
      this.containers.forEach((e) => e.remove())
    }
  }
}
